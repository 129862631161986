<template>
    <div>
        <v-row justify="start" align="center" class="mx-0 my-4">
            <h2 class="dashboard">Премия по сотрудникам</h2>
        </v-row>
        <v-data-table
            ref="dTable"
            class="expandable-table"
            :headers="[{ text: '', value: 'groupName', sortable: false, width: '100%' }]"
            :item-class="() => 'expandable-row'"
            :items="groupedData"
            :items-per-page="-1"
            hide-default-footer
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            show-expand
            item-key="groupId"
            :expanded.sync="expanded"
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" style="padding: 0px; border: 0">
                    <v-data-table
                        ref="table"
                        :headers="getHeaders(item.groupId)"
                        :items="item.data"
                        :items-per-page="-1"
                        class="recomendations-table report-table expanded-table"
                        color="main"
                        hide-default-footer
                    >
                        <template slot="body.append">
                            <tr class="summary-row" v-if="item.summaryRow">
                                <td
                                    v-for="header in getHeaders(item.groupId).filter(
                                        header => header.value !== 'userName' || header.value !== 'activation'
                                    )"
                                    class="summary-row"
                                    :class="{
                                        'text-right':
                                            header.value !== 'userName' && header.value !== 'activation',
                                    }"
                                    :key="header.value"
                                >
                                    {{ item.summaryRow[header.value] }}
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </td>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { sumBy } from 'lodash'
import { numberWithSpaces, numSort } from '@/utils'
import { toPercent } from '../helpers'

export default {
    props: {
        chartData: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        expanded: [],
        headers: [
            {
                text: 'Конверсия коммуникаций в запись (весь период)',
                value: 'totalConversion',
                align: 'right',
            },
            {
                text: 'Конверсия коммуникаций в запись (выбранный период)',
                value: 'conversion',
                align: 'right',
            },
            {
                text: 'Коммуникации / Отложили / Не напоминаем',
                value: 'actionsCount',
                align: 'right',
                sortable: false,
            },
            {
                text: 'Записей / Посещений',
                value: 'recordsAndAttendanceCount',
                align: 'right',
                sortable: false,
            },
            {
                text: 'Выручка от посещений',
                value: 'revenue',
                align: 'right',
                sort: numSort,
            },
            { text: 'Сумма премии', value: 'bonus', align: 'right', sort: numSort },
        ],
    }),
    computed: {
        groupedData() {
            const currentPeriodData = this.chartData.filter(el =>
                [el.bonus, el.revenue].every(v => !_.isNil(v))
            )
            const totalData = this.chartData.filter(el => [el.bonus, el.revenue].every(v => _.isNil(v)))
            const groupedByUserData = _.groupBy(currentPeriodData, 'user_name')
            const groupedByUserArr = Object.keys(groupedByUserData).map(key => ({
                groupId: key,
                groupName: key,
                data: groupedByUserData[key].map(el => {
                    const relatedTotalRow =
                        totalData.find(
                            totalEl =>
                                totalEl.activation_id === el.activation_id &&
                                totalEl.user_name === el.user_name
                        ) ?? {}
                    return Object.assign({}, el, {
                        total_communication_info: {
                            success_comm_count: relatedTotalRow.success_comm_count ?? 0,
                            comm_count_for_conversion: relatedTotalRow.comm_count_for_conversion ?? 0,
                            conversion: relatedTotalRow.conversion ?? 0,
                        },
                    })
                }),
            }))
            const result = [
                {
                    groupId: 'summary',
                    groupName: 'Общий итог',
                    data: groupedByUserArr.map((el, i) => {
                        const successCommCount = _.sumBy(el.data, 'success_comm_count')
                        const commCountForConversion = _.sumBy(el.data, 'comm_count_for_conversion')
                        const totalSuccessCommCount = _.sumBy(
                            el.data,
                            o => o.total_communication_info.success_comm_count
                        )
                        const totalCommCountForConversion = _.sumBy(
                            el.data,
                            o => o.total_communication_info.comm_count_for_conversion
                        )
                        return this.mapRowToTableFormat({
                            user_name: el.data[0].user_name,
                            activation_id: el.data[0].activation_id,
                            activation_name: el.data[0].activation_name,
                            bonus: _.sumBy(el.data, 'bonus'),
                            revenue: _.sumBy(el.data, 'revenue'),
                            ban_count: _.sumBy(el.data, 'ban_count'),
                            visit_cnt: _.sumBy(el.data, 'visit_cnt'),
                            comm_count: commCountForConversion,
                            conversion:
                                commCountForConversion === 0 ? 0 : successCommCount / commCountForConversion,
                            record_cnt: _.sumBy(el.data, 'record_cnt'),
                            postpone_count: _.sumBy(el.data, 'postpone_count'),
                            success_comm_count: successCommCount,
                            comm_count_for_conversion: _.sumBy(el.data, 'comm_count_for_conversion'),
                            total_communication_info: {
                                success_comm_count: totalSuccessCommCount,
                                comm_count_for_conversion: totalCommCountForConversion,
                                conversion:
                                    totalCommCountForConversion === 0
                                        ? 0
                                        : totalSuccessCommCount / totalCommCountForConversion,
                            },
                        })
                    }),
                    summaryRow: this.mapRowToTableFormat(
                        this.getSummaryRow(groupedByUserArr.flatMap(el => el.data))
                    ),
                },
            ].concat(
                groupedByUserArr.map(el => ({
                    groupId: el.groupId,
                    groupName: el.groupName,
                    data: el.data.map(this.mapRowToTableFormat),
                    summaryRow: Object.assign({}, this.mapRowToTableFormat(this.getSummaryRow(el.data)), {opz: ''}),
                }))
            )
            return result
        },
    },
    methods: {
        getHeaders(groupId) {
            if (groupId === 'summary') {
                return [{ text: 'Сотрудник', value: 'userName', width: '200px' }].concat(this.headers)
            }
            return [{ text: 'Активация', value: 'activation', width: '200px' }].concat(this.headers)
        },
        getSummaryRow(groupedData) {
            const successCommCount = _.sumBy(groupedData, 'success_comm_count')
            const commCountForConversion = _.sumBy(groupedData, 'comm_count_for_conversion')
            const totalSuccessCommCount = _.sumBy(
                groupedData,
                o => o.total_communication_info.success_comm_count
            )
            const totalCommCountForConversion = _.sumBy(
                groupedData,
                o => o.total_communication_info.comm_count_for_conversion
            )
            return {
                user_name: 'ОБЩИЙ ИТОГ',
                activation_id: 'summary',
                activation_name: 'ОБЩИЙ ИТОГ',
                bonus: _.sumBy(groupedData, 'bonus'),
                revenue: _.sumBy(groupedData, 'revenue'),
                ban_count: _.sumBy(groupedData, 'ban_count'),
                visit_cnt: _.sumBy(groupedData, 'visit_cnt'),
                comm_count: commCountForConversion,
                conversion: commCountForConversion === 0 ? 0 : successCommCount / commCountForConversion,
                record_cnt: _.sumBy(groupedData, 'record_cnt'),
                postpone_count: _.sumBy(groupedData, 'postpone_count'),
                success_comm_count: successCommCount,
                comm_count_for_conversion: _.sumBy(groupedData, 'comm_count_for_conversion'),
                total_communication_info: {
                    success_comm_count: totalSuccessCommCount,
                    comm_count_for_conversion: totalCommCountForConversion,
                    conversion:
                        totalCommCountForConversion === 0
                            ? 0
                            : totalSuccessCommCount / totalCommCountForConversion,
                },
            }
        },
        mapRowToTableFormat(el) {
            return {
                userName: el.user_name,
                activation: el.activation_name,
                totalConversion: `${toPercent(Number(el.total_communication_info.conversion).toFixed(2))} (${
                    el.total_communication_info.success_comm_count
                } / ${el.total_communication_info.comm_count_for_conversion})`,
                conversion: `${toPercent(Number(el.conversion).toFixed(2))} (${el.success_comm_count} / ${
                    el.comm_count_for_conversion
                })`,
                actionsCount: `${Number(el.comm_count)} / ${Number(el.postpone_count)} / ${Number(
                    el.ban_count
                )}`,
                recordsAndAttendanceCount: `${Number(el.record_cnt)} / ${Number(el.visit_cnt)}`,
                revenue: numberWithSpaces(Number(Math.round(el.revenue * 100) / 100)) || 0,
                bonus: numberWithSpaces(Number(Math.round(el.bonus * 100) / 100)) || 0,
            }
        },
    },
    created() {
        this.expanded = [this.groupedData.find(el => el.groupId === 'summary')]
    },
}
</script>
<style lang="sass" scoped>
@import '@/vars.sass'
::v-deep .expandable-table
    margin-bottom: 64px
::v-deep .expanded-table td
    padding-left: 12px !important
    padding-right: 12px !important
::v-deep .report table
    max-width: 1016px !important
    stable-layout: fixed !important

::v-deep .expandable-row
    cursor: pointer

::v-deep .v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded__content
    box-shadow: none
::v-deep .v-data-table.expandable-table> div > table > tbody > tr:nth-child(1) td
    border-top: thin solid $light-gray-2
::v-deep .expandable-row > td
    font-size: 16px !important
    font-weight: bold

::v-deep .v-data-table.expanded-table> div > table > tbody > tr:last-child td
    border-bottom: none !important

::v-deep .v-data-table.expandable-table > div > table > thead
    visibility: collapse
::v-deep thead th span
    font-size: 12px !important
::v-deep .p-12px
    padding-left: 12px !important
    padding-right: 12px !important

::v-deep .expandable-table table
    table-layout: fixed
    word-break: break-word
::v-deep .expandable-table table th
    padding: 0 12px !important
</style>
